/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHero',
        import: () => import(
        /* webpackChunkName: "InnerPageHero" */ '@stories/Widgets/InnerPageHero/InnerPageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerTabs',
        import: () => import(/* webpackChunkName: "InnerTabs" */ '@stories/Widgets/InnerTabs/InnerTabs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomepageHero',
        import: () => import(/* webpackChunkName: "HomepageHero" */ '@stories/Widgets/HomepageHero/HomepageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountDetails',
        import: () => import(
        /* webpackChunkName: "AccountDetails" */ '@stories/Widgets/Dashboard/AccountDetails/AccountDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AplHomepageHero',
        import: () => import(
        /* webpackChunkName: "AplHomepageHero" */ '@stories/Widgets/AplHomepageHero/AplHomepageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsArticleHero',
        import: () => import(
        /* webpackChunkName: "NewsArticleHero" */ '@stories/Widgets/NewsArticleHero/NewsArticleHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventDetailHero',
        import: () => import(
        /* webpackChunkName: "EventDetailHero" */ '@stories/Widgets/EventDetailHero/EventDetailHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AplSearchBanner',
        import: () => import(
        /* webpackChunkName: "AplSearchBanner" */ '@stories/Widgets/AplSearchBanner/AplSearchBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCard',
        import: () => import(/* webpackChunkName: "CtaCard" */ '@stories/Widgets/CtaCard/CtaCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CookieBar',
        import: () => import(/* webpackChunkName: "CookieBar" */ '@stories/Widgets/CookieBar/CookieBar'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'CookieBotDeclaration',
        import: () => import(
        /* webpackChunkName: "CookieBotDeclaration" */ '@stories/Widgets/CookieBotDeclaration/CookieBotDeclaration'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'DashboardMenu',
        import: () => import(
        /* webpackChunkName: "DashboardMenu" */ '@stories/Widgets/DashboardMenu/DashboardMenu'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PricingCard',
        import: () => import(/* webpackChunkName: "PricingCard" */ '@stories/Widgets/PricingCard/PricingCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FAQs',
        import: () => import(/* webpackChunkName: "FAQs" */ '@stories/Widgets/FAQs/FAQs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureVideo',
        import: () => import(/* webpackChunkName: "FeatureVideo" */ '@stories/Widgets/FeatureVideo/FeatureVideo'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureLists',
        import: () => import(
        /* webpackChunkName: "FeatureLists" */ '@stories/Widgets/FeatureListsGrid/FeatureListsGrid'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureListsCarousel',
        import: () => import(
        /* webpackChunkName: "FeatureListsCarousel" */ '@stories/Widgets/FeatureListsCarousel/FeatureListsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageWithCreditWidget',
        import: () => import(
        /* webpackChunkName: "ImageWithCreditWidget" */ '@stories/Widgets/ImageWithCredit/ImageWithCredit'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentSignpost',
        import: () => import(
        /* webpackChunkName: "ContentSignpost" */ '@stories/Widgets/ContentSignpost/ContentSignpost'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryChildPageList',
        import: () => import(
        /* webpackChunkName: "CategoryChildPageList" */ '@stories/Widgets/CategoryChildPageList/CategoryChildPageList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipProfileForm',
        import: () => import(
        /* webpackChunkName: "MembershipProfileForm" */ '@stories/Widgets/MembershipProfileForm/MembershipProfileForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MyDocuments',
        import: () => import(/* webpackChunkName: "MyDocuments" */ '@stories/Widgets/MyDocuments/MyDocuments'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import(
        /* webpackChunkName: "NewsListing" */ '@stories/Widgets/Listings/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PageListing',
        import: () => import(
        /* webpackChunkName: "PageListing" */ '@stories/Widgets/Listings/PageListing/PageListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventListing',
        import: () => import(
        /* webpackChunkName: "EventListing" */ '@stories/Widgets/Listings/EventListing/EventListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AdviceLineListing',
        import: () => import(
        /* webpackChunkName: "AdviceLineListing" */ '@stories/Widgets/Listings/AdviceLineListing/AdviceLineListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacancyListing',
        import: () => import(
        /* webpackChunkName: "VacancyListing" */ '@stories/Widgets/Listings/VacancyListing/VacancyListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CourseListing',
        import: () => import(
        /* webpackChunkName: "CourseListing" */ '@stories/Widgets/Listings/CourseListing/CourseListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import(/* webpackChunkName: "ShareButtons" */ '@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TextImage',
        import: () => import(/* webpackChunkName: "TextImage" */ '@stories/Widgets/TextImage/TextImage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import(/* webpackChunkName: "ImageGallery" */ '@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PodcastListing',
        import: () => import(
        /* webpackChunkName: "PodcastListing" */ '@stories/Widgets/Listings/PodcastListing/PodcastListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GatedContent',
        import: () => import(/* webpackChunkName: "GatedContent" */ '@stories/Widgets/GatedContent/GatedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ResourceListing',
        import: () => import(
        /* webpackChunkName: "ResourceListing" */ '@stories/Widgets/Listings/ResourceListing/ResourceListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PeopleListing',
        import: () => import(
        /* webpackChunkName: "PeopleListing" */ '@stories/Widgets/PeopleListing/PeopleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentList',
        import: () => import(/* webpackChunkName: "ContentList" */ '@stories/Widgets/ContentList/ContentList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VirtualTour',
        import: () => import(/* webpackChunkName: "VirtualTour" */ '@stories/Widgets/VirtualTour/VirtualTour'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ConsultantCard',
        import: () => import(
        /* webpackChunkName: "ConsultantCard" */ '@stories/Widgets/ConsultantCard/ConsultantCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactDetailsCard',
        import: () => import(
        /* webpackChunkName: "ContactDetailsCard" */ '@stories/Widgets/ContactDetailsCard/ContactDetailsCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsCarousel',
        import: () => import(/* webpackChunkName: "NewsCarousel" */ '@stories/Widgets/NewsCarousel/NewsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsCarousel',
        import: () => import(
        /* webpackChunkName: "EventsCarousel" */ '@stories/Widgets/EventsCarousel/EventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GoogleMap',
        import: () => import(/* webpackChunkName: "GoogleMap" */ '@stories/Widgets/GoogleMap/GoogleMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonials',
        import: () => import(/* webpackChunkName: "Testimonials" */ '@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RelatedResources',
        import: () => import(
        /* webpackChunkName: "RelatedResources" */ '@stories/Widgets/RelatedResources/RelatedResources'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import(/* webpackChunkName: "Timeline" */ '@stories/Widgets/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedMenu',
        import: () => import(/* webpackChunkName: "TabbedMenu" */ '@stories/Widgets/TabbedMenu/TabbedMenu'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Form',
        import: () => import(/* webpackChunkName: "Form" */ '@stories/Widgets/Form/Form'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProjectListing',
        import: () => import(
        /* webpackChunkName: "ProjectListing" */ '@stories/Widgets/Listings/ProjectListing/ProjectListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LongMemberCard',
        import: () => import(
        /* webpackChunkName: "LongMemberCard" */ '@stories/Widgets/LongMemberCard/LongMemberCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProjectCardWidget',
        import: () => import(
        /* webpackChunkName: "ProjectCardWidget" */ '@stories/Widgets/ProjectCard/ProjectCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import(
        /* webpackChunkName: "ProductListing" */ '@stories/Widgets/Listings/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProjectListingStatic',
        import: () => import(
        /* webpackChunkName: "ProjectListingStatic" */ '@stories/Widgets/Listings/ProjectListingStatic/ProjectListingStatic'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MemberListing',
        import: () => import(
        /* webpackChunkName: "MemberListing" */ '@stories/Widgets/Listings/HTAMemberListing/HTAMemberListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EditTestimonials',
        import: () => import(
        /* webpackChunkName: "EditTestimonials" */ '@stories/Widgets/Dashboard/EditTestimonials/EditTestimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EditProjects',
        import: () => import(
        /* webpackChunkName: "EditProjects" */ '@stories/Widgets/Dashboard/EditProjects/EditProjects'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EditTeams',
        import: () => import(/* webpackChunkName: "EditTeams" */ '@stories/Widgets/Dashboard/EditTeams/EditTeams'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EditPublicProfile',
        import: () => import(
        /* webpackChunkName: "EditPublicProfile" */ '@stories/Widgets/Dashboard/EditPublicProfile/EditPublicProfile'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AplLandscaperListing',
        import: () => import(
        /* webpackChunkName: "AplLandscaperListing" */ '@stories/Widgets/Listings/AplLandscaperListing/AplLandscaperListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedIFrame',
        import: () => import(
        /* webpackChunkName: "EmbeddedIFrame" */ '@stories/Widgets/EmbeddedIFrame/EmbeddedIFrame'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FiftyFiftyText',
        import: () => import(
        /* webpackChunkName: "FiftyFiftyText" */ '@stories/Widgets/FiftyFiftyText/FiftyFiftyText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CampaignPage',
        import: () => import(
        /* webpackChunkName: "CampaignPage" */ '@stories/Components/CampaignPage/CampaignPage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NotificationBanner',
        import: () => import(
        /* webpackChunkName: "NotificationBanner" */ '@stories/Widgets/NotificationBanner/NotificationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
];
